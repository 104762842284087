import Vue, { PluginObject } from 'vue'

const GlobalCssVarPlugin: PluginObject<Vue> = {
  install(Vue) {
    Vue.prototype.$globalCssVar = {
      '--sz-theme-primary-color': '#08c393',
      '--sz-theme-primary-color-opacity90': 'rgba(8, 195, 147, 0.9)',
      '--sz-theme-primary-color-opacity50': 'rgba(8, 195, 147, 0.5)',
      '--sz-theme-primary-color-opacity20': 'rgba(8, 195, 147, 0.2)',
      '--sz-theme-primary-color-opacity15': 'rgba(8, 195, 147, 0.15)',
      '--sz-theme-primary-color-opacity7': 'rgba(8, 195, 147, 0.07)',
      '--sz-theme-sub-color': '#0aa981',

      /* 警示提示色 */
      '--sz-warn-primary-color': '#fc7100',
      '--sz-warn-sub-color': '#f55200',

      /* 用于业务标签 */
      '--sz-tag-primary-color': '#3988b8',

      /* 中性色 */
      '--sz-title-color': '#333',
      '--sz-sub-title-color': '#9ea2a5',
      '--sz-content-color': '#666',
      '--sz-disabled-color': '#ccc',
      '--sz-line-color': '#eaeaea',
      '--sz-bg-color': '#f7f8fb',
      '--sz-tab-color': '#5d6377'
    }
  }
}

export default GlobalCssVarPlugin
