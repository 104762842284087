/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-12-02 20:49:41
 * 下单相关
 */
import { RouteConfig } from 'vue-router'
const basePath = '/order'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/confirm',
    name: 'orderConfirm',
    component: () => import('@/views/order/confirm'),
    meta: {
      title: '订单确认'
    }
  },
  {
    path: basePath + '/appointment',
    name: 'orderAppointment',
    component: () => import('@/views/order/appointment.vue'),
    meta: {
      title: '预约体检'
    }
  }
]

export default Routes
