/*
 * @Author: 王浩
 * @Date: 2021-06-17 17:17:20
 * @Last Modified by: nordon
 * @Last Modified time: 2021-09-06 09:32:07
 * 路由中间件, 通常用于页面切换过程中的一些初始化操作,如
 *  - 刷新登录态
 *  - 绑定用户支付信息
 *  - 同步微信分享信息
 *  ...
 */
import VueRouter from 'vue-router'
// import { EventEmitter } from '@/module/tools'
// 前置中间件
import updateTitle from './before/updateTitle'
import addEruda from './before/addEruda'
import bindLoginToken from './before/bindLoginToken'
import updateState from './before/updateState'

// 后置中间件
import initWechatShare from './after/init-wechat-share'

// const oEvent = new EventEmitter()

const middlewareConfig = {
  use(router: VueRouter): void {
    router.beforeEach(async (to, from, next) => {
      // 顺序执行全部的中间件
      // 中间件中严防阻塞用户流程，仅允许等待同步执行的流程
      // 禁止等待异步流程（如接口调用，事件订阅等）
      for (const middleware of this.before) {
        await middleware.fn(to, from)
      }

      // 删除单次执行中间件
      this.before = this.before.filter(item => item.mode !== 'once')

      // 进入页面
      next()
    })

    router.afterEach((to, from) => {
      this.after.forEach(afterMiddleWare => {
        // @ts-ignore
        afterMiddleWare(to, from)
      })
    })
  },
  before: [bindLoginToken, updateTitle, addEruda, updateState],
  after: [initWechatShare]
}

export default middlewareConfig
