<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="form flex-row-start" :style="formStyle">
    <div class="name" :style="nameStyle" v-html="name"></div>
    <div class="content">
      <div class="content-item" v-for="(item, index) in contentArr" :key="index" :style="contentStyle" v-html="item"></div>
      <div class="desc mt6" v-if="desc">{{ desc }}</div>
    </div>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'

// https://cn.vuejs.org/v2/guide/typescript.html
// https://github.com/vuejs/vue-class-component
@Component({
  components: {
    // About: () => import("../About.vue"),
  }
})
export default class Home extends Vue {
  // form的样式
  @Prop({
    type: Object,
    default() {
      return {}
    }
  })
  formStyle!: any

  // 标题
  @Prop({ type: String, default: '标题' }) name!: string

  // name的样式
  @Prop({
    type: Object,
    default() {
      return {}
    }
  })
  nameStyle!: any

  // 内容
  @Prop({ type: [String, Array], default: '内容' }) content!: any

  // content的样式
  @Prop({
    type: Object,
    default() {
      return {}
    }
  })
  contentStyle!: any

  // 描述
  @Prop({ type: String, default: '' }) desc!: string

  msg = '消息提示'

  /* 一.生命周期函数 */
  created(): void {}

  /* 二.监控函数 */
  @Watch('msg', { immediate: true, deep: true })
  onMsgChanged(val: string, oldVal: string): void {
    console.log('[msg 发生变化]', val, oldVal)
  }

  get contentArr(): Array<string> {
    if (typeof this.content === 'string') return [this.content]
    return this.content
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  // 事件调用函数注释
  actionXxx(): void {}

  /* ----------------------内部功能函数------------------------ */
  // 内部功能函数注释
  doSomething(): void {}

  /* ----------------------服务请求函数------------------------ */
  // 服务请求函数注释
  ajaxXxx(): void {}
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.form {
  align-items: flex-start;
  .name {
    color: #999;
    font-size: 0.28rem;
    line-height: 0.4rem;
    white-space: nowrap;
    margin-right: 0.56rem;
  }
  .content {
    &-item {
      color: #333;
      font-size: 0.28rem;
      font-weight: 700;
      line-height: 0.4rem;
      &:not(:first-child) {
        margin-top: 0.12rem;
      }
    }
    .desc {
      color: #999;
      font-size: 0.24rem;
    }
  }
}
</style>
