/*
 * @Author: 王浩
 * @Date: 2021-08-13 11:26:19
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-08-26 18:27:53
 * 地图相关 API
 */
import { getUa, isWechat } from './ua'

// 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
export function getDistance(lat1, lng1, lat2, lng2) {
  // 经纬度转换成三角函数中度分表形式
  const rad = d => (d * Math.PI) / 180.0
  const radLat1 = rad(parseFloat(lat1))
  const radLat2 = rad(parseFloat(lat2))
  const a = radLat1 - radLat2
  const b = rad(parseFloat(lng1)) - rad(parseFloat(lng2))
  let s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)))
  s = s * 6378.137 // EARTH_RADIUS
  s = Math.round(s * 10000) / 10000 //输出为公里
  return s
}

// 用户定位
interface UserPosition {
  latitude: number
  longitude: number
  msg: string | undefined
  status: string
}
let oPosition: UserPosition = {
  latitude: -1,
  longitude: -1,
  msg: '',
  status: 'pending' // pending success error
}
// 获取经纬度
export function getPosition(): Promise<UserPosition> {
  console.error('=====开始getPosition=====')
  return new Promise((resolve, reject) => {
    if (oPosition.status === 'success') {
      resolve(oPosition)
    } else if (oPosition.status === 'error') {
      reject(oPosition)
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          console.error('=====getPosition获取成功=====')
          console.error(latitude, longitude)
          oPosition = {
            latitude,
            longitude,
            msg: '成功',
            status: 'success'
          }
          resolve(oPosition)
        },
        function (err) {
          const code = err.code
          const msg = (function (n) {
            switch (n) {
              case 1:
                if (getUa().os === 'ios') {
                  return '暂未授权，无法使用<br>请前往手机“设置—通用—还原-还原位置和隐私”'
                } else {
                  return '地理位置未授权<br>请确认已开启定位授权”'
                }
              case 2:
                return '暂时获取不到位置信息'
              case 3:
                return '获取信息超时'
              case 4:
                return '未知错误'
            }
          })(code)

          oPosition = {
            latitude: -1,
            longitude: -1,
            msg,
            status: 'error'
          }

          reject(oPosition)
        },
        {
          maximumAge: 24 * 60 * 60 * 1000
        }
      )
    }
  })
}

export function openMap(data) {
  if (isWechat()) {
    // 调用微信地图
    getWechartConfig(data)
  } else {
    // 调用百度地图（建议采用第一种，因为要么根据机构名，要么根据坐标反向解析，根据机构名和坐标一起有风险且不友好，而反向解析不准确且有坐标转换风险）
    // 地理位置反向解析, 不准确
    window.open(
      `http://api.map.baidu.com/geocoder?location=${data.latitude},${data.longitude}&coord_type=gcj02&output=html&src=webapp.baidu.openAPIdemo`,
      '_blank'
    )
  }

  function getWechartConfig(item) {
    window.wx.ready(() => {
      window.wx.openLocation({
        debug: true,
        latitude: Number(item.latitude), // 纬度，浮点数，范围为90 ~ -90
        longitude: Number(item.longitude), // 经度，浮点数，范围为180 ~ -180。
        name: item.institName, // 位置名
        address: item.institAddr, // 地址详情说明
        scale: 15 // 地图缩放级别,整形值,范围从1~28。默认为最大
      })
    })
    window.wx.error(() => {
      vant.Toast('地图组件初始化失败')
    })
  }
}
