/*
 * @Author: 王浩
 * @Date: 2021-06-18 10:20:33
 * @Last Modified by: 王浩
 * @Last Modified time: 2022-04-19 14:49:23
 *
 */

interface uaInfo {
  os: 'android' | 'ios' | ''
  platform: 'alipay' | 'wechat' | ''
  isMiniProgram: boolean
}

export function getUa(): uaInfo {
  const data: uaInfo = {
    os: '', // 系统
    platform: '', // 平台
    isMiniProgram: false // 是否为小程序
  }
  const u = navigator.userAgent

  // 判断OS
  if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
    data.os = 'android'
  } else if (u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    data.os = 'ios'
  }

  // 判断用户平台
  if (u.match(/Alipay/i)) {
    // 支付宝app内，但不在支付宝小程序内
    data.platform = 'alipay'
  } else if (u.indexOf('MicroMessenger') >= 0 && u.indexOf('wxwork') < 0) {
    data.platform = 'wechat'
  }

  if (data.platform === 'alipay' && window.my) {
    data.isMiniProgram = u.includes('MiniProgram') || u.includes('AlipayIDE')
  } else if (data.platform === 'wechat') {
    data.isMiniProgram = window.__wxjs_environment === 'miniprogram'
  }

  return data
}

export function isWechat(): boolean {
  const uaInfo = getUa()

  return uaInfo.platform === 'wechat' && !uaInfo.isMiniProgram
}

export function isWechatMini(): boolean {
  const uaInfo = getUa()

  return uaInfo.platform === 'wechat' && uaInfo.isMiniProgram
}

export function isAlipay(): boolean {
  const uaInfo = getUa()

  return uaInfo.platform === 'alipay' && !uaInfo.isMiniProgram
}

export function isAlipayMini(): boolean {
  const uaInfo = getUa()

  return uaInfo.platform === 'alipay' && uaInfo.isMiniProgram
}

export function isMiniProgram(): boolean {
  return isWechatMini() || isAlipayMini()
}
