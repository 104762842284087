/**
 * 预约相关页面
 */

const conversion = () => import(/* webpackChunkName: "commerceConversion" */ '@/views/commerce/conversion.vue')
const result = () => import(/* webpackChunkName: "commerceResult" */ '@/views/commerce/result.vue')

const basePath = '/commerce'

export default [
  {
    path: basePath + '/conversion',
    name: 'commerceConversion',
    component: conversion,
    meta: {
      title: '兑换'
    }
  },
  {
    path: basePath + '/result',
    name: 'commerceResult',
    component: result,
    meta: {
      title: '兑换成功'
    }
  }
]
