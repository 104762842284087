/*
 * @Author: 王浩
 * @Date: 2021-06-24 15:22:40
 * @Last Modified by: nordon
 * @Last Modified time: 2021-09-06 09:29:48
 * AB 测试相关配置
 */
import Vue, { PluginObject } from 'vue'
import szGrayTest from 'sz-gray-test'

const SentryPlugin: PluginObject<Vue> = {
  install(Vue) {
    // 总体设置
    szGrayTest.init({
      isNewOrderProgress: 0.6, // 代表有60%的概率这个字段是true
      isShowTips: 0.4, // 代表有40%的概率这个字段是true
      examTabUrl: 0.5 // 体检tab A/B测试比例
    })

    Vue.prototype.$szGrayTest = szGrayTest // 挂到 Vue 的原型上，方便页面调用
  }
}

export default SentryPlugin
