/*
 * @Author: 王浩
 * @Date: 2021-06-18 11:08:54
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-09-06 23:41:04
 * 微信相关工具方法
 */
import { Vue } from 'vue-property-decorator'
import { loadScript } from './tools'

// 设置微信SDK
export function setWXSDK(vm: Vue): Promise<void> {
  const apiList = [
    'updateAppMessageShareData',
    'updateTimelineShareData',
    'onMenuShareTimeline', // （即将废弃）
    'onMenuShareAppMessage', // （即将废弃）
    'onMenuShareQQ', // （即将废弃）
    'onMenuShareWeibo',
    'onMenuShareQZone',
    'startRecord',
    'stopRecord',
    'onVoiceRecordEnd',
    'playVoice',
    'pauseVoice',
    'stopVoice',
    'onVoicePlayEnd',
    'uploadVoice',
    'downloadVoice',
    'chooseImage',
    'previewImage',
    'uploadImage',
    'downloadImage',
    'translateVoice',
    'getNetworkType',
    'openLocation',
    'getLocation',
    'hideOptionMenu',
    'showOptionMenu',
    'hideMenuItems',
    'showMenuItems',
    'hideAllNonBaseMenuItem',
    'showAllNonBaseMenuItem',
    'closeWindow',
    'scanQRCode',
    'chooseWXPay',
    'openProductSpecificView',
    'addCard',
    'chooseCard',
    'openCard'
  ]
  return new Promise((resolve, reject) => {
    loadScript('https://res.wx.qq.com/open/js/jweixin-1.6.0.js').finally(() => {
      console.error('load jweixin-1.6.0.js 成功')
      vm.$api
        .getSdkConfig()
        .then(res => {
          if (res.SZ_HEAD.RESP_CODE === 'S0000') {
            console.log('获取配置1')
            const { appId, timestamp, nonceStr, signature } = res.SZ_BODY
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId, // 必填，公众号的唯一标识
              timestamp, // 必填，生成签名的时间戳
              nonceStr, // 必填，生成签名的随机串
              signature, // 必填，签名，见附录1
              jsApiList: apiList, // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
              openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
            })
            console.log('获取配置2')
            wx.ready(() => {
              console.log('微信SDKready')
              resolve()
            })
            wx.error((errInfo: any) => {
              console.log('微信接口注册失败', errInfo)
              reject(errInfo)
            })
            console.log('获取配置3')
          }
        })
        .catch((err: any) => {
          console.log('获取配置出错', err.toString())
          reject(new Error('获取配置出错'))
        })
    })
  })
}
