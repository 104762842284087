/*
 * @Author: 王浩
 * @Date: 2023-01-06 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2023-01-06 23:45:11
 * 活动相关
 */
import { RouteConfig } from 'vue-router'
const basePath = '/activity/2023'

const Routes: Array<RouteConfig> = [
  {
    // 一月阳康活动 http://wiki.wayyue.com/pages/viewpage.action?pageId=38834238
    path: basePath + '/yk',
    name: 'positiveRecovery',
    component: () => import('@/views/activity/2023/yk.vue'),
    meta: {
      title: '善诊'
    }
  }
]

export default Routes
