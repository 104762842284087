import Vue from 'vue'

const middleware = {
  mode: 'interval', // once (仅页面初始化时执行一次) | interval (循环执行)
  fn(): Promise<void> {
    return new Promise(resolve => {
      // if (window.$sa.registerPage) {
      //   window.$sa.registerPage({
      //     is_login: !!Vue.prototype.$getCookie('maskMobile')
      //   })
      // }
      resolve()
    })
  }
}

export default middleware
