<!------------------------------------------- template ------------------------------------------->
<template>
  <div class="sz-instit">
    <van-popup class="sz-popup" get-container="body" position="bottom" v-model="value" @closed="actionClosed">
      <div class="sz-popup-header">
        <span>选择机构</span>
        <div class="sz-popup-header-close" @click="value = false">
          <sz-icon class="ft16" name="close"></sz-icon>
        </div>
      </div>
      <div class="sz-instit-warpper">
        <!-- 选择地区 -->
        <div v-if="currentRegionData.regionCode" class="sz-instit-hospital-region" @click="isShowRegion = !isShowRegion">
          <sz-icon name="dingwei" class="ft16 mr5" color="#9EA2A5"></sz-icon>
          <span v-if="selectRegionList">{{ selectRegionList.map(item => item.regionChnName).join(' ') }}</span>
          <span v-else-if="currentRegionData">{{ currentRegionData.regionChnName }}</span>
          <sz-icon style="transform: rotate(90deg)" name="more" class="ft12 ml5" color="#9EA2A5"></sz-icon>
        </div>
      </div>

      <div class="sz-popup-body">
        <div class="instit-list">
          <van-loading v-if="isInstitLoading" class="instit-list-loading" />
          <template v-else>
            <Instit
              :free="free"
              :reserve-query="reserveQuery"
              :longitude="longitude"
              :latitude="latitude"
              :goods-code="goodsCode"
              :order-code="orderCode"
              :add-goods-code-list-str="addGoodsCodeListStr"
              :activity-code="activityCode"
              :gender="gender"
              v-for="(item, index) in institList"
              :key="index"
              :data="item"
              @on-update-instit="actionUpdateInstitData($event, index)"
              @click="actionShowReserveDay(item, '点击机构卡片')"
              @on-btn="actionShowReserveDay(item, '点击预约时间')"
            ></Instit>
          </template>
        </div>
      </div>
    </van-popup>

    <!-- 选择地区 -->
    <szRegion
      :reserve-query="reserveQuery"
      :add-goods-code-list-str="addGoodsCodeListStr"
      :order-code="orderCode"
      :goods-code="goodsCode"
      :gender="gender"
      @on-complete="actionChangeRegion"
      v-model="isShowRegion"
    ></szRegion>

    <!-- 选择日期 -->
    <ChooseDate @on-complete="actionChangeDate" ref="chooseDate" v-model="isShowChooseDate"></ChooseDate>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script lang="ts">
import { Component, Watch, Prop, Mixins } from 'vue-property-decorator'
import { getPosition, getDistance } from '@/module/tools'
import GetPositionMixins from '@/module/mixins/getPosition'
// interface RegionData {
//   municipalityRegion: boolean // 是否为直辖市
//   regionChnName: string // 地区名称
//   regionCityCapAlp: string // 地区首字母
//   regionCode: string // 地区编号
// }
// https://cn.vuejs.org/v2/guide/typescript.html
// https://github.com/vuejs/vue-class-component
@Component({
  model: {
    prop: 'value',
    event: 'change'
  },
  mixins: [GetPositionMixins],
  components: {
    Instit: () => import('./components/instit.vue'), // 机构卡片
    ChooseDate: () => import('./components/chooseDate.vue') // 选择日期
  }
})
export default class Home extends Mixins(GetPositionMixins) {
  // Mixins 相关函数需要声明防止报错
  @Prop() private value!: boolean
  @Prop({ default: false }) private useOldApi!: boolean
  @Prop({ default: false }) private reserveQuery!: boolean // 是否为预约时查询， 预约时字段为 true 后端有特殊逻辑
  @Prop() private goodsCode!: string // 商品编号，有商品编号时，查询可约地区需要调用新接口
  @Prop() private orderCode!: string // 订单编号 - 通过订单获取当前订单支持预约的地区
  @Prop() private addGoodsCodeListStr!: string // 加项包编号
  @Prop() private activityCode!: string // 活动编号 （和活动框架编号不是一个东西）
  @Prop() private gender!: string // 用户性别
  @Prop() private regionData!: any // 机构编号
  @Prop({ default: true }) private free!: boolean

  isCalledGetLocation = false // 是否已调用过获取定位接口
  isInstitLoading = false // 机构数据是否加载中
  isShowRegion = false // 是否展示地区组件
  isShowChooseDate = false // 是否展示选择日期
  selectRegionList: any = null // 当前选中的地区列表
  longitude: string | number = '' // 经度
  latitude: string | number = '' // 纬度

  institList: Array<any> = [] // 机构列表
  currentRegionData = {
    // 当前定位的地区数据
    municipalityRegion: false,
    regionChnName: '',
    regionCode: ''
  }

  /* 一.生命周期函数 */
  created(): void {
    if (this.regionData) {
      console.error('有regionData')
      console.error(this.regionData)
      this.currentRegionData = this.regionData
    } else {
      console.error('无regionData')
      console.error(this.regionData)
      this.mix_ajaxQueryDefaultCity().then(data => {
        this.currentRegionData = data
      })
    }
  }

  /* 二.监控函数 */
  @Watch('value')
  onMsgChanged(val: boolean): void {
    if (!val) {
      this.selectRegionList = null
      this.institList = []
    } else {
      let isAuthed = false
      setTimeout(() => {
        if (isAuthed) {
          this.mix_ajaxQueryCityByLocationOnce()
            .then(data => {
              this.selectRegionList = null
              this.currentRegionData = data
              this.initInstitData(this.currentRegionData)
            })
            .catch(() => {
              this.initInstitData(this.currentRegionData)
            })
        } else {
          this.initInstitData(this.currentRegionData)
        }
      }, 30)
      getPosition().then(res => {
        this.longitude = res.longitude
        this.latitude = res.latitude
        isAuthed = true
      })
    }
  }

  /* 三.内部功能函数 */
  /* ----------------------事件调用函数------------------------ */
  actionUpdateInstitData(institData: any, index: number) {
    this.institList.splice(index, 1, institData)
  }
  // 展示预约日期组件
  actionShowReserveDay(item, description) {
    console.log(item)
    const oVm: any = this.$refs.chooseDate
    oVm.getReserveDate({
      goodsCode: this.goodsCode,
      institCode: item.institCode,
      institData: item,
      orderCode: this.orderCode,
      activityCode: this.activityCode,
      addGoodsCodeList: this.addGoodsCodeListStr,
      reserveQuery: this.reserveQuery
    })
    this.isShowChooseDate = !this.isShowChooseDate
  }

  // 弹窗关闭
  actionClosed() {
    this.$emit('change', false)
  }
  // 切换地区
  actionChangeRegion(regionList) {
    this.isShowRegion = false
    this.selectRegionList = regionList

    if (regionList.length > 0) {
      const d = regionList[regionList.length - 1]
      this.ajaxQueryInstit(d.regionCode)
    }
  }

  // 选择日期
  actionChangeDate({ date, institData }) {
    this.isShowChooseDate = false

    this.$emit('on-complete', {
      date,
      institData
    })
  }

  /* ----------------------内部功能函数------------------------ */
  // 初始化
  initInstitData({ regionCode, regionChnName, municipalityRegion }) {
    this.currentRegionData.municipalityRegion = municipalityRegion
    this.currentRegionData.regionCode = regionCode
    this.currentRegionData.regionChnName = regionChnName
    this.ajaxQueryInstit(regionCode)
  }

  // 根据距离排序
  sortByDistance(institList) {
    return institList
      .map(item => {
        const distance = getDistance(this.latitude, this.longitude, item.latitude, item.longitude)
        item.distance = distance
        return item
      })
      .sort((a, b) => {
        return a.distance - b.distance
      })
  }

  /* ----------------------服务请求函数------------------------ */
  // 通过地区编号查询机构
  ajaxQueryInstit(regionCode) {
    if (this.isInstitLoading) return
    this.isInstitLoading = true
    this.$api
      .slget('region_instits', {
        reserveQuery: this.reserveQuery,
        l2RegionCode: regionCode,
        goodsCode: this.goodsCode,
        activityCode: this.activityCode,
        addGoodsCodeList: this.addGoodsCodeListStr,
        gender: this.gender,
        orderCode: this.orderCode,
        isShowReserveDate: 0,
        trueResult: true,
        location: this.latitude && this.longitude ? `${this.latitude},${this.longitude}` : ''
      })
      .then(res => {
        if (res.SZ_HEAD.RESP_CODE === 'S0000') {
          if (this.longitude) {
            // 需要排序
            this.institList = this.sortByDistance(res.SZ_BODY.INSTIT_D || [])
          } else {
            this.institList = res.SZ_BODY.INSTIT_D || []
          }
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG)
        }
      })
      .finally(() => {
        this.isInstitLoading = false
      })
  }

  // 查询默认城市
  ajaxQueryDefaultCity() {
    return this.$api
      .slget('cgi_commonDictQueryByKeyAndName', {
        key: 'DEFAULT_CITY',
        name: 'DEFAULT_CITY_NAME'
      })
      .then(res => {
        if (res.SZ_HEAD.RESP_CODE === 'S0000') {
          this.currentRegionData = JSON.parse(res.SZ_BODY.cacheDto.content)
        } else {
          this.$toast(res.SZ_HEAD.RESP_MSG)
        }
      })
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
.sz-popup {
  height: 85vh;
  border-radius: 32px 32px 0 0;
  display: flex;
  flex-direction: column;
  &-header {
    width: 100%;
    position: relative;
    font-size: 36px;
    line-height: 100px;
    text-align: center;
    font-weight: 700;
    &-close {
      color: #c8c9cc;
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 100px;
    }
  }
  &-body {
    position: relative;
    flex: 1;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
.sz-instit {
  // 选医院
  &-hospital {
    padding-bottom: 20px;
    &-region {
      width: 100%;
      padding: 32px;
      border-bottom: 20px solid #f7f7f7;
      background: #fff;
      z-index: 1;
    }
  }
}
.instit-list {
  width: 100%;
  &-loading {
    margin-top: 100px;
    display: flex;
    justify-content: center;
  }
}
</style>
