/*
 * @Author: 王浩
 * @Date: 2021-07-07 10:07:41
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-12-02 20:38:21
 * 个人中心相关路由
 */
import { RouteConfig } from 'vue-router'
const basePath = '/user'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/index',
    name: 'userIndex',
    meta: {
      title: '个人中心'
    },
    component: () => import('@/views/user/index.vue')
  },
  {
    path: basePath + '/order_list',
    name: 'userOrderList',
    component: () => import('@/views/user/orderList.vue'),
    meta: {
      title: '体检订单'
    }
  },
  {
    path: basePath + '/tj_detail',
    name: 'userOrderDetail',
    component: () => import('@/views/user/orderDetail.vue'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: basePath + '/setting',
    name: 'userSetting',
    component: () => import('@/views/user/setting.vue'),
    meta: {
      title: '设置'
    }
  },
  {
    path: basePath + '/problem_center',
    name: 'userProblemCenter',
    component: () => import('@/views/user/problemCenter.vue'),
    meta: {
      title: '问题中心'
    }
  },
  {
    path: basePath + '/examiner_manage',
    name: 'userExaminerManage',
    component: () => import('@/views/user/examinerManage.vue'),
    meta: {
      title: '体检人管理'
    }
  },
  {
    path: basePath + '/refund',
    name: 'refund',
    component: () => import('@/views/user/refund.vue'),
    meta: {
      title: '退款申请'
    }
  },
  {
    path: basePath + '/member_equity',
    name: 'memberEquity',
    component: () => import('@/views/user/memberEquity.vue'),
    meta: {
      title: '会员权益'
    }
  },
  {
    path: basePath + '/service_order_list',
    name: 'serviceOrderList',
    component: () => import('@/views/user/serviceOrder.vue'),
    meta: {
      title: '服务订单'
    }
  },
  {
    path: basePath + '/about',
    name: 'about',
    component: () => import('@/views/user/about.vue'),
    meta: {
      title: '关于'
    }
  },
  {
    path: basePath + '/assistant_poster',
    name: 'assistantPoster',
    component: () => import('@/views/user/assistantPoster.vue'),
    meta: {
      title: '添加小助手'
    }
  },
  // {
  //   path: basePath + '/customizeDetail',
  //   name: 'customizeDetail',
  //   component: customizeDetail,
  //   meta: {
  //     title: '定制订单详情'
  //   }
  // },
  // {
  //   path: basePath + '/tj_detail',
  //   name: 'userTjDetail',
  //   component: userTjDetail,
  //   meta: {
  //     title: '订单详情'
  //   }
  // },
  {
    path: basePath + '/ticket',
    name: 'userTicket',
    component: () => import('@/views/user/ticket.vue'),
    meta: {
      title: '我的抵扣券'
    }
  },
  {
    path: basePath + '/health_list',
    name: 'healthList',
    component: () => import('@/components/serviceOrder/health_list.vue'),
    meta: {
      title: '健康服务订单'
    }
  },
  {
    path: basePath + '/health_detail',
    name: 'healthDetail',
    component: () => import('@/components/serviceOrder/health_detail.vue'),
    meta: {
      title: '健康服务详情'
    }
  },
  {
    // 健康顾问付费订单列表
    path: basePath + '/consultant_list',
    name: 'consultantList',
    component: () => import('@/components/serviceOrder/consultant_list.vue'),
    meta: {
      title: '顾问服务订单'
    }
  },
  {
    // 健康顾问付费订单详情
    path: basePath + '/consultant_detail',
    name: 'consultantDetail',
    component: () => import('@/components/serviceOrder/consultant_detail.vue'),
    meta: {
      title: '顾问服务详情'
    }
  },
  {
    path: basePath + '/health_report',
    name: 'healthReport',
    component: () => import('@/components/serviceOrder/health_report.vue'),
    meta: {
      title: '健康报告'
    }
  },
  // {
  //   path: basePath + '/report_list',
  //   name: 'userReportList',
  //   component: userReportList,
  //   meta: {
  //     title: '报告列表'
  //   }
  // },
  // {
  //   path: basePath + '/report_list_del',
  //   name: 'userReportList1',
  //   component: userReportList1,
  //   meta: {
  //     title: '报告列表'
  //   }
  // },
  {
    path: basePath + '/ask_list',
    name: 'askList',
    component: () => import('@/components/serviceOrder/ask_list.vue'),
    meta: {
      title: '问诊订单'
    }
  },
  {
    path: basePath + '/ask_detail',
    name: 'askDetail',
    component: () => import('@/components/serviceOrder/ask_detail.vue'),
    meta: {
      title: '问诊订单'
    }
  },
  // 挂号信息
  {
    path: basePath + '/registration_info',
    name: 'registration_info',
    meta: {
      title: '预约服务',
      engTitle: 'Confirm registration'
    },
    component: () => import('@/views/registration/registration_info.vue')
  },
  // 挂号结果
  {
    path: basePath + '/registration_result',
    name: 'registration_result',
    meta: {
      title: '预约服务',
      engTitle: 'Confirm registration'
    },
    component: () => import('@/views/registration/registration_result.vue')
  }

  // {
  //   path: basePath + '/bind_login',
  //   name: 'userBindLogin',
  //   component: userBindLogin,
  //   meta: {
  //     title: ''
  //   }
  // },
  // {
  //   path: basePath + '/about',
  //   name: 'about',
  //   component: about,
  //   meta: {
  //     title: ''
  //   }
  // },
  // {
  //   path: basePath + '/auth_option',
  //   name: 'authOption',
  //   component: authOption,
  //   meta: {
  //     title: ''
  //   }
  // }
]

export default Routes
