/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2022-03-02 20:05:02
 * 家庭健康服务
 */
import { RouteConfig } from 'vue-router'
const basePath = '/service'

const Routes: Array<RouteConfig> = [
  // 结果页
  {
    path: basePath + '/result',
    name: 'serviceResult',
    component: () => import('@/views/service/result.vue'),
    meta: {
      title: '立即扫码，开始定制'
    }
  }
  // // 健康卫士（暂时下线）
  // {
  //   path: basePath + '/health',
  //   alias: '/wap/custom/health',
  //   name: 'healthGuard',
  //   component: HEALTH,
  //   meta: {
  //     title: '善诊家庭健康顾问'
  //   }
  // },
  // // 家庭医生服务列表页
  // {
  //   path: basePath + '/courseList',
  //   alias: '/wap/custom/courseList',
  //   name: 'courseList',
  //   component: COURSE,
  //   meta: {
  //     title: '家庭健康服务'
  //   }
  // },
  // // 家庭医生服务详情页
  // {
  //   path: basePath + '/courseDetail',
  //   alias: '/wap/custom/courseDetail',
  //   name: 'courseDetail',
  //   component: DETAIL,
  //   meta: {
  //     title: '家庭健康服务'
  //   }
  // },
  // // 家庭医生服务详情页
  // {
  //   path: basePath + '/freeCourseDetail',
  //   alias: '/wap/custom/freeCourseDetail',
  //   name: 'freeCourseDetail',
  //   component: FREECOURSEDETAIL,
  //   meta: {
  //     title: '家庭健康课'
  //   }
  // },
  // // 家庭医生协议
  // {
  //   path: basePath + '/serviceExplain',
  //   name: 'serviceExplain',
  //   component: SERVICEEXPLAIN,
  //   meta: {
  //     title: '善诊健康服务用户协议'
  //   }
  // },
  // // 家庭医生健康助手页面
  // {
  //   path: basePath + '/familyHealthAssitant',
  //   name: 'familyHealthAssitant',
  //   component: FAMILYHEALTHASSITANT,
  //   meta: {
  //     title: '善诊健康服务'
  //   }
  // },
  // {
  //   path: basePath + '/specialCourseList',
  //   name: 'specialCourseList',
  //   component: SPECIALCOURSELIST,
  //   meta: {
  //     title: '善诊健康服务'
  //   }
  // },
  // {
  //   path: basePath + '/courseStory',
  //   name: 'courseStory',
  //   component: COURSESTORY,
  //   meta: {
  //     title: '善诊健康服务'
  //   }
  // },
  // {
  //   path: basePath + '/custom_result',
  //   name: 'buyCustomServiceSuccess',
  //   component: buyCustomServiceSuccess,
  //   meta: {
  //     title: '支付成功',
  //     isSilentAuth: true
  //   }
  // }
]

export default Routes
