const attentionGZH = () => import('@/views/messageLinks/attentionGZH/index.vue')
const bookMethod = () => import('@/views/messageLinks/bookMethod/index.vue')
const checkReport = () => import('@/views/messageLinks/checkReport/index.vue')
const basePath = '/messagesLinks'
export default [
  {
    path: basePath + '/attentionGZH',
    name: 'attentionGZH',
    component: attentionGZH,
    meta: {
      title: '关注公众号'
    }
  },
  {
    path: basePath + '/bookMethod',
    name: 'bookMethod',
    component: bookMethod,
    meta: {
      title: '预约方法'
    }
  },
  {
    path: basePath + '/checkReport',
    name: 'checkReport',
    component: checkReport,
    meta: {
      title: '查看报告'
    }
  }
]
