/*
 * @Author: 王浩
 * @Date: 2021-07-06 17:05:29
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-08-04 20:05:56
 * DB 插件
 */
import { PluginObject } from 'vue'
import szIndexedDB from 'sz-indexdb'

const IndexedDBPlugin: PluginObject<Vue> = {
  install(Vue) {
    const DB_CONFIG = {
      name: 'SZ_COMMON',
      version: 3,
      cacheTime: 300 * 1000,
      objectStoreList: [
        {
          name: 'WX_SHARE', // 数据对象名称
          keyPath: 'primaryKey', // 主键
          autoIncrement: false // 主键是否自增
        },
        {
          name: 'ACTIVITY_INFO', // 数据对象名称
          keyPath: 'primaryKey', // 主键
          autoIncrement: false // 主键是否自增
        },
        {
          name: 'GOODS_SERIES_LIST', // 数据对象名称
          keyPath: 'primaryKey', // 主键
          autoIncrement: false // 主键是否自增
        }
      ]
    }

    Vue.prototype.$db = new szIndexedDB(DB_CONFIG)
  }
}

export default IndexedDBPlugin
