/*
 * @Author: 王浩
 * @Date: 2021-07-07 10:07:41
 * @Last Modified by: nordon
 * @Last Modified time: 2021-09-17 16:56:19
 * 商品相关路由
 */
import { RouteConfig } from 'vue-router'
const basePath = '/goods'

const Routes: Array<RouteConfig> = [
  // 商品首页
  {
    path: basePath + '/goods_index',
    name: 'goodsIndex',
    component: () => import('@/views/goods/goodsIndex'),
    meta: {
      title: '体检'
    }
  },
  {
    path: basePath + '/parentHealth',
    name: 'parentHealth',
    component: () => import('@/views/goods/parentHealth'),
    meta: {
      title: '父母健康'
    }
  },
  {
    path: basePath + '/recommend',
    name: 'goodsRecommend',
    component: () => import('@/views/goods/recommend'),
    meta: {
      title: '套餐列表'
    }
  },

  // 商品详情
  {
    path: basePath + '/goods_detail',
    name: 'goodsDetail',
    component: () => import('@/views/goods/goodsDetail'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: basePath + '/query_instit',
    name: 'goodsQueryInstit',
    component: () => import('@/views/goods/queryInstit'),
    meta: {
      title: '查询机构'
    }
  },
  {
    path: basePath + '/hospital_detail',
    name: 'goodsHospitalDetail',
    component: () => import('@/views/goods/hospitalDetail'),
    meta: {
      title: '体检中心详情'
    }
  },
  {
    path: basePath + '/special_exam',
    name: 'goodsSpecialExam',
    component: () => import('@/views/goods/specialExam'),
    meta: {
      title: '　'
    }
  },
  {
    path: basePath + '/special_exam_detail',
    name: 'goodsSpecialExamDetail',
    component: () => import('@/views/goods/specialExam/detail.vue'),
    meta: {
      title: '套餐详情'
    }
  },
  {
    path: basePath + '/eb_goods_list', // 电商商品列表
    name: 'ebGoodsList',
    component: () => import('@/views/goods/ebGoods/list.vue'),
    meta: {
      title: '善诊体检'
    }
  },
  {
    path: basePath + '/eb_goods_detail', // 电商商品详情
    name: 'ebGoodsDetail',
    component: () => import('@/views/goods/ebGoods/detail.vue'),
    meta: {
      title: '套餐详情'
    }
  }
]

export default Routes
