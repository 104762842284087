/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-08-18 23:45:11
 * 下单相关
 */
import { RouteConfig } from 'vue-router'
const basePath = '/authorization'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/authorise',
    name: 'authorizationAuthorise',
    component: () => import('@/views/authorization/authorise.vue'),
    meta: {
      title: '授权获取报告'
    }
  },
  {
    path: basePath + '/result',
    name: 'authorizationResult',
    component: () => import('@/views/authorization/result.vue'),
    meta: {
      title: '授权获取报告'
    }
  }
]

export default Routes
