import { RouteConfig } from 'vue-router'
const basePath = '/consultant'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/index',
    name: 'consultantIndex',
    component: () => import('@/views/consultant/index.vue'),
    meta: {
      title: '顾问服务',
      hideMenuItems: [
        'menuItem:share:qq', // 分享到QQ
        'menuItem:share:weiboApp', // 分享到Weibo
        'menuItem:favorite', // 收藏
        'menuItem:share:facebook', // 分享到FB
        'menuItem:share:QZone', // 分享到 QQ 空间
        'menuItem:editTag', // 编辑标签
        'menuItem:delete', // 删除
        'menuItem:copyUrl', // 复制链接
        'menuItem:originPage', // 原网页
        'menuItem:readMode', // 阅读模式
        'menuItem:openWithQQBrowser', // 在QQ浏览器中打开
        'menuItem:openWithSafari', // 在Safari中打开
        'menuItem:share:email', // 邮件
        'menuItem:share:brand' // 一些特殊公众号
      ]
    }
  },
  {
    path: basePath + '/qrCode',
    name: 'consultantQrCode',
    component: () => import('@/views/consultant/qrCode.vue'),
    meta: {
      title: '顾问服务',
      hideMenuItems: [
        'menuItem:share:qq', // 分享到QQ
        'menuItem:share:weiboApp', // 分享到Weibo
        'menuItem:favorite', // 收藏
        'menuItem:share:facebook', // 分享到FB
        'menuItem:share:QZone', // 分享到 QQ 空间
        'menuItem:editTag', // 编辑标签
        'menuItem:delete', // 删除
        'menuItem:copyUrl', // 复制链接
        'menuItem:originPage', // 原网页
        'menuItem:readMode', // 阅读模式
        'menuItem:openWithQQBrowser', // 在QQ浏览器中打开
        'menuItem:openWithSafari', // 在Safari中打开
        'menuItem:share:email', // 邮件
        'menuItem:share:brand' // 一些特殊公众号
      ]
    }
  },
  {
    path: basePath + '/result',
    name: 'consultantResult',
    component: () => import('@/views/consultant/result.vue'),
    meta: {
      title: '顾问服务',
      hideMenuItems: [
        'menuItem:share:qq', // 分享到QQ
        'menuItem:share:weiboApp', // 分享到Weibo
        'menuItem:favorite', // 收藏
        'menuItem:share:facebook', // 分享到FB
        'menuItem:share:QZone', // 分享到 QQ 空间
        'menuItem:editTag', // 编辑标签
        'menuItem:delete', // 删除
        'menuItem:copyUrl', // 复制链接
        'menuItem:originPage', // 原网页
        'menuItem:readMode', // 阅读模式
        'menuItem:openWithQQBrowser', // 在QQ浏览器中打开
        'menuItem:openWithSafari', // 在Safari中打开
        'menuItem:share:email', // 邮件
        'menuItem:share:brand' // 一些特殊公众号
      ]
    }
  },
  {
    path: basePath + '/question',
    name: 'consultantQuestion',
    component: () => import('@/views/consultant/question.vue'),
    meta: {
      title: '顾问服务',
      hideMenuItems: [
        'menuItem:share:qq', // 分享到QQ
        'menuItem:share:weiboApp', // 分享到Weibo
        'menuItem:favorite', // 收藏
        'menuItem:share:facebook', // 分享到FB
        'menuItem:share:QZone', // 分享到 QQ 空间
        'menuItem:editTag', // 编辑标签
        'menuItem:delete', // 删除
        'menuItem:copyUrl', // 复制链接
        'menuItem:originPage', // 原网页
        'menuItem:readMode', // 阅读模式
        'menuItem:openWithQQBrowser', // 在QQ浏览器中打开
        'menuItem:openWithSafari', // 在Safari中打开
        'menuItem:share:email', // 邮件
        'menuItem:share:brand' // 一些特殊公众号
      ]
    }
  }
]
export default Routes
