<!------------------------------------------- template ------------------------------------------->
<template>
  <transition v-if="fade" name="fade">
    <div :class="classes" :style="wraperStyles" @click.stop="check">
      <span :class="dotClasses" :style="bgColorStyle" v-if="showDot"></span>
      <span :class="textClasses" :style="textColorStyle"><slot></slot></span>
      <Icon :class="iconClass" :color="lineColor" v-if="closable" type="ios-close" @click.native.stop="close"></Icon>
    </div>
  </transition>
  <div :class="classes" :style="wraperStyles" v-else @click.stop="check">
    <span :class="dotClasses" :style="bgColorStyle" v-if="showDot"></span>
    <span :class="textClasses" :style="textColorStyle"><slot></slot></span>
    <Icon :class="iconClass" :color="lineColor" v-if="closable" type="ios-close" @click.native.stop="close"></Icon>
  </div>
</template>

<!-------------------------------------------- script -------------------------------------------->
<script>
const prefixCls = 'sz-tag'
const initColorList = [
  'default',
  'primary',
  'success',
  'warning',
  'error',
  'blue',
  'green',
  'red',
  'yellow',
  'pink',
  'magenta',
  'volcano',
  'orange',
  'gold',
  'lime',
  'cyan',
  'geekblue',
  'purple'
]
const colorList = ['pink', 'magenta', 'volcano', 'orange', 'gold', 'lime', 'cyan', 'geekblue', 'purple']

export default {
  name: 'sz-tag',
  components: {},
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    checkable: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'default'
    },
    type: {
      type: String,
      default: 'default'
    },
    name: {
      type: [String, Number]
    },
    fade: {
      type: Boolean,
      default: true
    },
    // 4.0.0
    size: {
      validator(value) {
        return this.oneOf(value, ['default', 'medium', 'large'])
      },
      default: 'default'
    }
  },

  data() {
    return {
      isChecked: this.checked
    }
  },

  /* 一.生命周期函数 */
  created() {},

  /* 二.监控函数 */
  watch: {
    checked(val) {
      this.isChecked = val
    }
  },

  computed: {
    classes() {
      return [
        `${prefixCls}`,
        `${prefixCls}-size-${this.size}`,
        {
          [`${prefixCls}-${this.color}`]: !!this.color && this.oneOf(this.color, initColorList),
          [`${prefixCls}-${this.type}`]: this.oneOf(this.type, ['border', 'dot']),
          [`${prefixCls}-closable`]: this.closable,
          [`${prefixCls}-checked`]: this.isChecked
        }
      ]
    },
    wraperStyles() {
      return this.oneOf(this.color, initColorList)
        ? {}
        : {
            background: this.isChecked ? this.defaultTypeColor : 'transparent',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: this.type !== 'dot' && this.type !== 'border' && this.isChecked ? this.borderColor : this.lineColor,
            color: this.lineColor
          }
    },
    textClasses() {
      return [
        `${prefixCls}-text`,
        this.type === 'border' ? (this.oneOf(this.color, initColorList) ? `${prefixCls}-color-${this.color}` : '') : '',
        this.type !== 'dot' && this.type !== 'border' && this.color !== 'default'
          ? this.isChecked && colorList.indexOf(this.color) < 0
            ? `${prefixCls}-color-white`
            : ''
          : ''
      ]
    },
    dotClasses() {
      return `${prefixCls}-dot-inner`
    },
    iconClass() {
      if (this.type === 'dot') {
        return ''
      } else if (this.type === 'border') {
        return this.oneOf(this.color, initColorList) ? `${prefixCls}-color-${this.color}` : ''
      } else {
        return this.color !== undefined ? (this.color === 'default' ? '' : 'rgb(255, 255, 255)') : ''
      }
    },
    showDot() {
      return !!this.type && this.type === 'dot'
    },
    lineColor() {
      if (this.type === 'dot') {
        return ''
      } else if (this.type === 'border') {
        return this.color !== undefined ? (this.oneOf(this.color, initColorList) ? '' : this.color) : ''
      } else {
        return this.color !== undefined ? (this.color === 'default' ? '' : 'rgb(255, 255, 255)') : ''
      }
    },
    borderColor() {
      return this.color !== undefined ? (this.color === 'default' ? '' : this.color) : ''
    },
    dotColor() {
      return this.color !== undefined ? (this.oneOf(this.color, initColorList) ? '' : this.color) : ''
    },
    textColorStyle() {
      return this.oneOf(this.color, initColorList)
        ? {}
        : this.type !== 'dot' && this.type !== 'border'
        ? this.isChecked
          ? { color: this.lineColor }
          : {}
        : { color: this.lineColor }
    },
    bgColorStyle() {
      return this.oneOf(this.color, initColorList) ? {} : { background: this.dotColor }
    },
    defaultTypeColor() {
      return this.type !== 'dot' && this.type !== 'border'
        ? this.color !== undefined
          ? this.oneOf(this.color, initColorList)
            ? ''
            : this.color
          : ''
        : ''
    }
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx() {},

    /* ----------------------内部功能函数------------------------ */
    oneOf(value, validList) {
      for (let i = 0; i < validList.length; i++) {
        if (value === validList[i]) {
          return true
        }
      }
      return false
    },
    // 内部功能函数注释
    doSomething() {},
    close(event) {
      if (this.name === undefined) {
        this.$emit('on-close', event)
      } else {
        this.$emit('on-close', event, this.name)
      }
    },
    check() {
      if (!this.checkable) return
      const checked = !this.isChecked
      this.isChecked = checked
      if (this.name === undefined) {
        this.$emit('on-change', checked)
      } else {
        this.$emit('on-change', checked, this.name)
      }
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {}
  }
}
</script>

<!-------------------------------------------- style -------------------------------------------->
<style scoped lang="scss">
$tag-prefix-cls: 'sz-tag';

.#{$tag-prefix-cls} {
  display: inline-flex;
  align-items: center;
  height: rem(40);
  padding: 0 rem(8);
  overflow: hidden;
  color: #fff;
  font-size: rem(24);
  line-height: 1;
  background: #33c293;
  // border: 1px solid @border-color-split;
  border-radius: rem(4);
  opacity: 1;

  /** 默认类型 */
  /** 名称 */
  // red 红色按钮
  &.#{$tag-prefix-cls}-red {
    color: #fe4f38;
    background: rgba(#fe4f38, 0.12);
  }
  // orange 橙色按钮
  &.#{$tag-prefix-cls}-orange {
    color: #ff6500;
    background: rgba(#ff6500, 0.12);
  }
  // blue 蓝色按钮
  &.#{$tag-prefix-cls}-blue {
    color: #3185f2;
    background: rgba(#3185f2, 0.12);
  }

  /** 边框类型 */
  &.#{$tag-prefix-cls}-border {
    color: #33c293;
    background: transparent;
    border: 1px solid #33c293;

    /** 名称 */
    // red 红色按钮
    &.#{$tag-prefix-cls}-red {
      color: #fe4f38;
      border-color: #fe4f38;
    }
    &.#{$tag-prefix-cls}-orange {
      color: #ff6500;
      border-color: #ff6500;
    }
    // blue 蓝色按钮
    &.#{$tag-prefix-cls}-blue {
      color: #3185f2;
      border-color: #3185f2;
    }
  }

  // 类型

  //
  // mr6 sz-tag sz-tag-size-default sz-tag-primary sz-tag-border sz-tag-checked
}
</style>
