import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/home'
import MiddleWare from './middleware'
import Routes from './routes'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: '/', redirect: { name: 'home' } }, // 首页进入重定向到home
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '首页'
    },
    component: Home
  }
]
console.log('process.env.VUE_APP_ROUTER_BASE', process.env.VUE_APP_ROUTER_BASE)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  base: process.env.VUE_APP_ROUTER_BASE,
  routes: [...routes, ...Routes]
})

MiddleWare.use(router)

export default router
