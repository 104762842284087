const reportV2Index = () => import('@/views/report_v2/reportIndex.vue')
const reportV2HealthTest = () => import('@/views/report_v2/healthTest.vue')
const reportV2Compare = () => import('@/views/report_v2/compare/list.vue')
const reportV2ChooseExaminer = () => import('@/views/report_v2/compare/chooseExaminer.vue')
const reportV2Audio = () => import('@/views/report_v2/reportV2Audio.vue')
import { RouteConfig } from 'vue-router'
const basePath = '/report_v2'
const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/index',
    name: 'reportV2Index',
    component: reportV2Index,
    meta: {
      title: '体检报告',
      hideMenuItems: [
        'menuItem:copyUrl' // 复制链接
      ]
    }
  },
  {
    path: basePath + '/health_test',
    name: 'reportV2HealthTest',
    component: reportV2HealthTest,
    meta: {
      title: '健康测评'
    }
  },
  {
    path: basePath + '/compare',
    name: 'reportV2Compare',
    component: reportV2Compare,
    meta: {
      title: '报告中心'
    }
  },
  {
    path: basePath + '/compare/choose_examiner',
    name: 'reportV2ChooseExaminer',
    component: reportV2ChooseExaminer,
    meta: {
      title: '报告对比'
    }
  },
  {
    path: basePath + '/report_audio',
    name: 'reportV2Audio',
    component: reportV2Audio,
    meta: {
      title: '报告解读'
    }
  }
]
export default Routes
