import BaseAPI from './base'

class API extends BaseAPI {
  /**
   * 查询商品列表
   * */
  queryGoodsList(activityCode: string) {
    return this.get(
      '/is/wap/query/goodsList',
      {
        activityCode
      },
      {
        cache: true
      }
    )
  }
  // 查询是否为新用户
  queryNewUserCheck(data: any) {
    return this.get('/is/wap/user/newUserCheck', data)
  }

  queryGoodsDetail(data: any) {
    return this.get('/is/wap/query/goodsDetail', data, {
      cache: true
    })
  }

  // 查询用户会员相关信息
  queryVipUserInfo() {
    return this.get('/is/wap/query/vipUserInfo')
  }

  // 查询一代报告异常标签
  queryReportPreTag(orderCode: string) {
    return this.get('/is/wap/query/reportPreTag', {
      orderCode
    })
  }

  // 查询一代报告详情
  queryReportLevel1Detail(data: any) {
    return this.get('/is/wap/query/reportLevel1Detail', data)
  }

  // 查询一代报告图片文件
  queryReportLevel1File(data: any) {
    return this.get('/is/wap/query/reportLevel1File', data)
  }
  // 二代报告查询体检概况
  queryReportSummaryByOrderCode(data: any) {
    return this.get('/is/wap/query/reportSummaryByOrderCode', data)
  }

  // 二代报告查询异常报告
  queryReportAbnormalItemsByOrderCode(data: any) {
    return this.get('/is/wap/query/reportAbAbnormalItemsByOrderCode', data)
  }
  // 二代报告异常详情
  queryReportAbnormalSummary(data: any) {
    return this.get('/is/wap/query/reportAbnormalSummary', data)
  }

  // 二代报告查询报告语音
  queryReportMedia(orderCode: any) {
    return this.get('/is/wap/query/reportMedia', {
      orderCode
    })
  }

  // 医生列表查询
  queryDoctorList(data: any) {
    return this.get('/is/wap/query/doctor_list', data)
  }

  // 报告解读下单
  interpretOrdered(data: any) {
    return this.slget('order_interpretOrdered', data)
  }

  // 查询解读订单详情
  interpretDetail(data: any) {
    return this.get('/is/wap/query/interpret_detail', data)
  }
  // 二代报告查询详情
  queryReportItemsByOrderCode(data: any) {
    return this.get('/is/wap/query/reportItemsByOrderCode', data)
  }
  // 查询优惠券列表
  queryCouponList(data) {
    return this.slpost('mkt_canUseCoupon', data)
  }

  // 兑换券接口
  exchangeCoupon(data) {
    return this.slget('mkt_couponExchange', data)
  }

  // 查询体检订单详情
  queryOrderExamSummary(data) {
    return this.get('/is/wap/query/orderExamSummary', data)
  }

  // 取消订单
  examCancel(data) {
    return this.get('/is/wap/exam/cancel', data)
  }
  async getCityByLocation(data) {
    return this.slget('getCityByLocation', data, { cache: true })
  }

  // 登出
  loginOut() {
    return this.get('/is/wap/logi/loginOut')
  }

  // 家庭医生--配置化二维码
  familyHeathQrCode(data) {
    return this.get('/is/wap/familyHealth/familyHeathQrCode', data)
  }

  // 查询问诊订单列表
  queryMedicalOrderList(data) {
    return this.post('/is/wap/ask/medical_order_list', data)
  }

  // 查询问诊聊天记录
  getMedicalDialogue(data) {
    return this.post('/is/wap/ask/get_medical_dialogue', data)
  }
  // 查询健康服务订单列表
  queryHealthList(data) {
    return this.get('/is/wap/familyHealth/familyHeathOrderList', data)
  }

  /**
   * 家庭医生订单-订单详情
   * @param data
   * @returns {*}
   */
  familyHeathOrderDetail(data) {
    return this.get('/is/wap/familyHealth/familyHeathOrderDetail', data)
  }

  // 生成小程序码或者公众号二维码
  /*
    http://wiki.wayyue.com/pages/viewpage.action?pageId=10126399（二）
     */
  generateOrCode(data) {
    return this.post('/is/wap/wx_qrcode/generate', data)
  }
  // 获取推荐健康课课程列表
  qrCodeUpdate(data) {
    return this.post('/is/wap/course/qrCodeUpdate', data)
  }
  //查询商品是否是再来一件的活动商品
  // forActivities(data) {
  //   return this.slpost('promotion/promotionQueryByGoodsCode', data)
  // }
}
export default API
