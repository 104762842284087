var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('szPage',{staticClass:"home",on:{"on-scroll":_vm.actionScroll}},[_c('div',{staticClass:"home-body"},[(_vm.mainAdDtoList.length > 0)?_c('div',{staticClass:"home-menu"},[_c('div',{staticClass:"home-menu-item",on:{"click":function($event){_vm.trackClick({ description: _vm.mainAdDtoList[0].title }, () => {
              _vm.actionRouteTo('goodsRecommend')
            })}}},[_c('div',{staticClass:"home-menu-item-title"},[_c('span',[_vm._v(_vm._s(_vm.mainAdDtoList[0].title))]),_c('sz-icon',{staticClass:"home-menu-item-title-icon ft18 ml4",attrs:{"name":"xiangqing2"}})],1),_c('div',{staticClass:"home-menu-item-desc"},[_vm._v(_vm._s(_vm.mainAdDtoList[0].desc))])]),_c('div',{staticClass:"home-menu-item",on:{"click":function($event){_vm.trackClick({ description: _vm.mainAdDtoList[1].title }, () => {
              _vm.actionRouteTo('userOrderList')
            })}}},[_c('div',{staticClass:"home-menu-item-title"},[_c('span',[_vm._v(_vm._s(_vm.mainAdDtoList[1].title))]),_c('sz-icon',{staticClass:"home-menu-item-title-icon ft18 ml4",attrs:{"name":"xiangqing2"}})],1),_c('div',{staticClass:"home-menu-item-desc"},[_vm._v(_vm._s(_vm.mainAdDtoList[1].desc))])])]):_vm._e(),_c('div',{staticClass:"home-nav"},[_c('div',{staticClass:"home-nav-item",on:{"click":function($event){_vm.trackClick({ description: '查看报告' }, () => {
              _vm.actionCheckLoginJumpTo('/wap2/report_v2/compare')
            })}}},[_c('img',{staticClass:"home-nav-item-icon",attrs:{"src":require("@/assets/images/home/nav2.png")}}),_c('div',{staticClass:"home-nav-item-title"},[_vm._v("查看报告")])]),_c('div',{staticClass:"home-nav-item",on:{"click":function($event){_vm.trackClick({ description: '咨询套餐' }, () => {
              _vm.actionJumpTo('/cooperation/little-helper?activityCode=ACT670277396')
            })}}},[_c('img',{staticClass:"home-nav-item-icon",attrs:{"src":require("@/assets/images/home/nav5.png")}}),_c('div',{staticClass:"home-nav-item-title"},[_vm._v("咨询套餐")])]),_c('div',{staticClass:"home-nav-item",on:{"click":function($event){_vm.trackClick({ description: '善太医体检金刚位' }, () => {
              _vm.actionJumpTo('/custom/recommend/index')
            })}}},[_c('div',{staticClass:"home-nav-item-icon styicon"},[_c('img',{attrs:{"width":"100%","src":require("@/assets/images/home/nav4.png")}})]),_c('div',{staticClass:"home-nav-item-title"},[_vm._v("善太医体检")])]),_c('div',{staticClass:"home-nav-item",on:{"click":function($event){_vm.trackClick({ description: '体检百科' }, () => {
              _vm.actionJumpTo('/data/wiki?pageSource=wap')
            })}}},[_c('img',{staticClass:"home-nav-item-icon",attrs:{"src":require("@/assets/images/home/nav3.png")}}),_c('div',{staticClass:"home-nav-item-title"},[_vm._v("体检百科")])])]),(_vm.isLogin && _vm.orderData)?_c('div',{staticClass:"home-orderstatus"},[_c('div',{staticClass:"home-orderstatus-title"},[_vm._v(_vm._s(_vm.getOrderStatus.title))]),_c('div',{staticClass:"home-orderstatus-btn",on:{"click":function($event){return _vm.actionClickOrderBtn(_vm.getOrderStatus.link)}}},[_vm._v(" "+_vm._s(_vm.getOrderStatus.btnText)+" ")])]):_vm._e(),(_vm.swipeList.length > 0)?_c('div',{staticClass:"home-banner"},[_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000},on:{"change":_vm.actionSwipeChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"home-banner-pagination"},[_vm._l((_vm.swipeList),function(item,index){return _c('div',{key:index,staticClass:"home-banner-pagination-item",class:{ active: _vm.activeSwipeNum === index }})}),(_vm.isLogin && _vm.isUserFilial === 1)?_c('div',{staticClass:"home-banner-pagination-item",class:{ active: _vm.activeSwipeNum === _vm.swipeList.length }}):_vm._e()],2)]},proxy:true}],null,false,3345488729)},[(_vm.isLogin && _vm.isUserFilial === 1)?_c('van-swipe-item',{staticClass:"home-banner-content"},[_c('img',{attrs:{"width":"100%","src":require('@/assets/images/home/newcomer-red-envelope.png')},on:{"click":_vm.actionToOldCustomerActivity}})]):_vm._e(),_vm._l((_vm.swipeList),function(item,index){return _c('van-swipe-item',{key:index,staticClass:"home-banner-content"},[_c('img',{attrs:{"width":"100%","src":_vm._f("img")(item.banner)},on:{"click":function($event){_vm.trackClick({ description: `点击运营位${index + 1}` }, () => {
                  _vm.actionJumpTo(item.link)
                })}}})])})],2)],1):_vm._e(),_c('div',{staticClass:"home-goods"},[_c('div',{staticClass:"home-goods-title"},[_vm._v("精选体检")]),_c('GoodsList',{ref:"goodsList",attrs:{"theme-colors":{
            tabColor: '#0CC393', //  Tab 栏颜色
            tabLineColor: '#08C393', // Tab 栏下划线颜色
            priceColor: '#FF4200'
          },"change-title":false,"page-style":_vm.getGoodsListStyle,"price-prefix":_vm.isVip ? '会员价' : '券后',"goods-btn-title":"查看套餐","activityCode":"ACT091543228"},on:{"on-goods-detail":_vm.actionToGoodsDetail,"change-tab":_vm.actionChangeTab,"on-complate":_vm.actionScroll}})],1),_c('div',{ref:"styBanner",staticClass:"mt10",on:{"click":_vm.actionToStyRecommend}},[_c('img',{attrs:{"width":"100%","src":require("@/assets/images/home/sty_banner.png")}})]),_c('div',{staticClass:"home-hospitalinfo"},[_c('img',{attrs:{"width":"100%","src":require("@/assets/images/home/hospital_info.png")}})])]),_c('szFooter',{attrs:{"slot":"footer","name":"home"},slot:"footer"})],1),(_vm.getPopUpAdData && _vm.$isWechat)?_c('pop-up-ad',{attrs:{"data":_vm.getPopUpAdData,"show-page":"HOME_PAGE"}}):_vm._e(),(!_vm.isLogin)?_c('div',{staticClass:"home-red-envelope",on:{"click":function($event){_vm.trackClick({ description: '点击去登录' }, () => {
        _vm.actionCheckLoginJumpTo(`https://m.shanzhen.com/wap2/home?isNoviceLogin=1`)
      })}}},[_c('img',{staticClass:"red-envelope",attrs:{"src":require("@/assets/images/home/red-envelope.png"),"alt":""}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }