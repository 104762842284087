/*
 * @Author: 王浩
 * @Date: 2021-06-22 10:43:34
 * @Last Modified by: 王浩
 * @Last Modified time: 2022-04-19 14:55:32
 * 神策埋点相关
 */

import Vue, { PluginObject } from 'vue'
import { getUa, loadScript, getCookie, isMiniProgram, getQueryString } from '@/module/tools'

interface SA_Mock {
  eventList: Array<any>
  track(eventName: any, eventDetail: any, cb: any): void
}

const SentryPlugin: PluginObject<Vue> = {
  install(Vue) {
    // Mock 是为了让神策异步加载，保存用户触发的点，同时在神策加载结束后将埋点补发，从而让页面更快的显示
    const samock: SA_Mock = {
      eventList: [],
      track(eventName, eventDetail, cb) {
        this.eventList.push([eventName, eventDetail])
        cb && cb()
      }
    }

    // window.$sa = samock
    // Vue.prototype.$sa = samock

    loadScript('https://static.shanzhen.com/js/sa-sdk-javascript@1.17.1/sensorsdata.min.js').finally(() => {
      console.log('神策', window.sensorsDataAnalytic201505)
      const fn = () => {
        const sa = window.sensorsDataAnalytic201505
        if (sa) {
          // Vue.prototype.$sa = sa
          // window.$sa = sa // 这里是为了方便在API模块中调用
          this.initSa()
          console.log('神策初始化结束', samock.eventList.length)
          // 补发埋点
          this.triggerEventList(samock.eventList)
        }
      }

      // 放开用户授权后才允许记录埋点的限制
      fn()
      // const isAgreeProto = window.$authDialog.getISAgreeProto()
      // if (isAgreeProto) {
      //   console.log('[神策] 用户已授权，正常初始化')
      //   fn()
      // } else {
      //   window.$authDialog.on('auth-ok', () => {
      //     console.log('[神策] 授权回调，正常初始化')
      //     fn()
      //   })
      // }
    })
  },
  initSa() {
    const sa = window.sensorsDataAnalytic201505
    if (!sa) {
      console.log('[初始化神策失败 未找到变量]')
      return
    }
    // 初始化神策埋点
    try {
      // 初始化神策
      sa.init({
        server_url: process.env.VUE_APP_SENSORS_URL,
        show_log: process.env.VUE_APP_SENSORS_DEBUG === '1',
        web_url: process.env.VUE_APP_SENSORS_URL,
        heatmap: {
          scroll_delay_time: 1000,
          isTrackLink: true
        },
        scrollmap: {
          collect_url: () => {
            return this.heatmapConfig.includes(location.pathname)
          }
        }
      })

      // 小程序中绑定 distinctID
      if (isMiniProgram()) {
        const distinctID = getQueryString('distinctID')
        if (distinctID) sa.identify(distinctID, true)
      }

      // 注册公用属性
      /**
       * 注册公用属性
       * 注意在注册时 is_login 和 is_member 需要读取本地缓存
       * 同时在缓存发生变化时，需要及时更新状态
       */
      sa.registerPage({
        platform_type: this.getPlatformType(),
        biz_type: this.getBizType(),
        is_login: !!getCookie('maskMobile'),
        plf_type: '善诊'
        // is_member: false, // true or false
      })

      /**
       * 在登录状态下，需要使用 szMemberCode 做登录
       * szMemberCode 通常是使用手机号登录 或 绑定手机号之后才会生成
       */
      if (getCookie('islogin') === '1' && getCookie('szMemberCode')) {
        sa.login(getCookie('szMemberCode'))
      }

      // @ts-ignore namespace 暂时不知道如何扩充属性，暂时 ignore
      sa.__logout = function () {
        sa.logout(true)
      }

      console.log('神策注册结束')
    } catch (e) {
      console.log(e)
    }
  },
  // 初始化后补发暂存埋点
  triggerEventList(eventList: any) {
    const sa = window.sensorsDataAnalytic201505
    if (eventList.length > 0 && sa) {
      eventList.forEach(tarckData => {
        sa.track(...tarckData)
      })
    }
  },
  // 神策埋点触达图设置， 需要采集的页面path
  heatmapConfig: [
    // '/wap/report/index', // 二代报告页
    // '/wap/goods/goods_index' // 体检频道页
  ],
  /**
   * 获取平台类型
   * @returns
   */
  getPlatformType() {
    const { platform, os } = getUa()

    let platformType = ''
    const isMobile = os === 'android' || os === 'ios'
    const isAlipay = platform === 'alipay'
    const isWx = platform === 'wechat'

    if (isMiniProgram()) {
      platformType = '小程序'
    } else if (isAlipay) {
      platformType = 'ali'
    } else if (isWx) {
      platformType = 'wechat'
    } else if (isMobile) {
      platformType = 'mobile'
    } else {
      platformType = 'pc'
    }

    return platformType
  },
  /**
   * 获取业务类型
   * @returns
   */
  getBizType() {
    const { platform } = getUa()

    let bizType = ''
    if (platform === 'alipay') {
      bizType = '生活号'
    } else if (platform === 'wechat') {
      bizType = '公众号'
    } else {
      // 网页
      bizType = ''
    }
    return bizType
  }
}

export default SentryPlugin
