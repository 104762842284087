/* ------------------------------------------- template ------------------------------------------- */
<template>
  <div
    id="defaultDragComp"
    class="defaultDragComp"
    @click="$emit('on-click')"
    @touchstart="actionMoveStart"
    @touchmove="actionMoving"
    @touchend="actionMoveEnd"
  >
    <slot></slot>
  </div>
</template>

/* -------------------------------------------- script -------------------------------------------- */
<script>
export default {
  name: '',
  components: {},
  props: {
    padding: {
      type: Number,
      default: 16
    }
  },

  data() {
    return {
      defaultDragComp: null,
      flags: false, // 是否正在拖拽
      position: { x: 0, y: 0 }, // 初始位置
      nx: '', // 移动过程当中的坐标x
      ny: '' // 移动过程当中的坐标y
    }
  },

  /* 一.生命周期函数 */
  created() {},

  /* 二.监控函数 */
  watch: {},

  computed: {
    // 缓存选中的元素
    dragComp() {
      return this.storeDomId()
    }
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 开始移动
    actionMoveStart(event) {
      this.flags = true
      let touch
      if (event.touches) {
        touch = event.touches[0]
      } else {
        touch = event
      }
      this.maxW = document.body.clientWidth - this.dragComp.offsetWidth
      this.maxH = document.body.clientHeight - this.dragComp.offsetHeight
      this.position.x = touch.clientX - this.dragComp.offsetLeft
      this.position.y = touch.clientY - this.dragComp.offsetTop
    },
    // 移动中
    actionMoving(event) {
      event.preventDefault()
      if (this.flags) {
        let touch
        if (event.touches) {
          touch = event.touches[0]
        } else {
          touch = event
        }
        this.nx = touch.clientX - this.position.x
        this.ny = touch.clientY - this.position.y

        if (this.nx < 0) {
          this.nx = 0
        } else if (this.nx > this.maxW) {
          this.nx = this.maxW
        }

        if (this.ny < 0) {
          this.ny = 0
        } else if (this.ny >= this.maxH) {
          this.ny = this.maxH
        }

        this.dragComp.style.left = this.nx + 'px'
        this.dragComp.style.top = this.ny + 'px'
        // // 阻止页面的滑动默认事件
        // document.addEventListener(
        //   'touchmove',
        //   function () {
        //     event.preventDefault()
        //   },
        //   false
        // )
      }
    },
    // 移动结束
    actionMoveEnd() {
      // 距离右边的距离
      const LEFT = this.padding
      let body = document.body
      if (this.dragComp.offsetLeft + this.dragComp.offsetWidth / 2 <= body.offsetWidth / 2) {
        this.dragComp.style.left = `${LEFT}px`
      } else {
        // 都设置left，否则会冲突导致后面不生效
        this.dragComp.style.left = body.offsetWidth - LEFT - this.dragComp.offsetWidth + 'px'
      }
      this.flags = false
      // 移除监听事件
      // document.removeEventListener('touchmove', false)
    },
    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    storeDomId() {
      if (!this.defaultDragComp) {
        this.defaultDragComp = document.getElementById('defaultDragComp')
      }
      return this.defaultDragComp
    },
    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {}
  }
}
</script>

/* -------------------------------------------- style -------------------------------------------- */
<style scoped lang="scss">
.defaultDragComp {
  position: fixed;
  z-index: 100;
}
</style>
