import { Module } from 'vuex'
import { RootState } from '@/store/types'

interface GlobalState {
  [key: string]: any
}

const GlobalModule: Module<GlobalState, RootState> = {
  namespaced: true,
  state: {
    test: '1'
  },
  mutations: {},
  actions: {},
  getters: {
    // 获取 State 数据
    data(state, getters, rootState) {
      console.log(state, getters, rootState)
      return state
    }
  }
}

export default GlobalModule
