import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { RootState } from './types'
import { getCookie, getQueryString } from '@/module/tools'

// 数据持久化插件
import createPersistedState from 'vuex-persistedstate'

// 导入模块
import globalModule from './modules/global'
// import report from './modules/report'

Vue.use(Vuex)

export declare class MyStore extends Store<RootState> {
  global?: any
}

const store: MyStore = new Vuex.Store({
  plugins: [
    createPersistedState({
      key: 'vuex_persistedstate_wap2',
      paths: ['$_report']
    })
  ],
  modules: {
    $_global: globalModule // $_report: report
  },
  state: {
    isVip: false, // 用户是否为会员（旧版本逻辑）
    // 用户是否为会员（旧版本逻辑）个人中心需要展示会员
    isUserVip: getCookie('islogin') === '1' && getCookie('szVip') === '1',
    isLogin: getCookie('islogin') === '1', // 用户是否已登录。
    fromCompany: sessionStorage.getItem('BCM_fromCompany') || getQueryString('fromCompany') || '',
    codeInvalidDate: sessionStorage.getItem('codeInvalidDate') || getQueryString('codeInvalidDate') || ''
  },
  mutations: {
    setLoginState(state, isLogin) {
      state.isLogin = isLogin
    }
  },
  actions: {},
  getters: {}
})

// 兼容旧的逻辑
store.global = {
  set(key: string, value: any) {
    store.commit('$_global/setState', {
      [key]: value
    })
  }
}

console.log(store.state)

Object.defineProperty(store.global, 'state', {
  get() {
    return store.getters['$_global/data']
  }
})

export default store
