/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-08-05 10:45:52
 * 提示页面，展示提示信息等
 */
import { RouteConfig } from 'vue-router'
const basePath = '/tips'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/tips',
    name: 'tips',
    component: () => import('@/views/tips/tips'),
    meta: {
      title: '友情提示'
    }
  }
]

export default Routes
