/*
 * @Author: 王浩
 * @Date: 2021-06-17 17:40:47
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-09-09 21:49:54
 * 微信分享相关配置
 */

// 基本类
// 举报: "menuItem:exposeArticle"
// 调整字体: "menuItem:setFont"
// 日间模式: "menuItem:dayMode"
// 夜间模式: "menuItem:nightMode"
// 刷新: "menuItem:refresh"
// 查看公众号（已添加）: "menuItem:profile"
// 查看公众号（未添加）: "menuItem:addContact"

// 传播类
// 发送给朋友: "menuItem:share:appMessage"
// 分享到朋友圈: "menuItem:share:timeline"
// 分享到QQ: "menuItem:share:qq"
// 分享到Weibo: "menuItem:share:weiboApp"
// 收藏: "menuItem:favorite"
// 分享到FB: "menuItem:share:facebook"
// 分享到 QQ 空间 "menuItem:share:QZone"

// 保护类
// 编辑标签: "menuItem:editTag"
// 删除: "menuItem:delete"
// 复制链接: "menuItem:copyUrl"
// 原网页: "menuItem:originPage"
// 阅读模式: "menuItem:readMode"
// 在QQ浏览器中打开: "menuItem:openWithQQBrowser"
// 在Safari中打开: "menuItem:openWithSafari"
// 邮件: "menuItem:share:email"
// 一些特殊公众号: "menuItem:share:brand"
import Vue from 'vue'
import { Route } from 'vue-router'
import { isWechat, isObject } from '@/module/tools'

const _vm = new Vue()

function initWechatShare(to: Route): void {
  console.log('后置中间件执行： initWechatShare', to)
  if (!isWechat()) return
  if (!isObject(to)) {
    throw new Error('initWxShare should have object param')
  }
  // 格式化
  const baseUrl = location.origin + location.pathname
  const _array: Array<string> = []
  for (const property in to.query) {
    if (property !== '_t') {
      const _parameterPair = property + '=' + to.query[property]
      _array.push(_parameterPair)
    }
  }
  const parameterPairs = _array.join('&')

  const cacheKey = encodeURIComponent(baseUrl + parameterPairs)

  _vm.$db.ready(() => {
    // 获取后端配置信息
    _vm.$db.WX_SHARE.get(cacheKey)
      .then((cacheData: any) => {
        console.log('读取缓存', cacheData)
        setWxShare(cacheData.data, to)
      })
      .catch((err: any) => {
        console.log('读取缓存报错', err)
        _vm.$api
          .getShareConfig({
            baseUrl,
            parameterPairs
          })
          .then(res => {
            if (res.SZ_HEAD.RESP_CODE === 'S0000') {
              setWxShare(res.SZ_BODY, to)
              // 保存活动信息
              _vm.$db.WX_SHARE.put({
                primaryKey: cacheKey,
                data: res.SZ_BODY
              })
            } else {
              const commonResult = {
                imageUrl: 'img/pageShareConfig/头像.png',
                jumpUrl: `${location.origin}/wap2/home`,
                remark: '在善诊，已经有超过300万人为父母预约了体检，父母健康找善诊',
                switchOff: 0,
                title: '善诊'
              }
              // 如果当前url没配置，就配置默认分享
              setWxShare(commonResult, to)

              _vm.$db.WX_SHARE.put({
                primaryKey: cacheKey,
                data: commonResult
              })
            }
          })
      })
  })

  // 初始化微信分享
  function setWxShare(_data: any, to: Route) {
    if (!to) return
    _vm
      .$wxReady(_vm)
      .then(() => {
        // 需在用户可能点击分享按钮前就先调用
        console.log('进入wx.ready', _data)

        const { hideMenuItems, skipShare = false } = to.meta || {}

        // 跳过分享，跳过分享通常表示页面需要自行处理分享，所以需要跳过
        // 跳过时默认展示全部按钮
        if (skipShare) {
          wx.showOptionMenu()
          wx.showAllNonBaseMenuItem()
          return
        }
        // 限制分享
        if (_data.switchOff) {
          wx.hideOptionMenu()
          wx.hideAllNonBaseMenuItem()
        } else if (hideMenuItems && hideMenuItems.length) {
          // 先展示所有按钮
          wx.showOptionMenu()
          wx.showAllNonBaseMenuItem()

          // 再隐藏对应的按钮
          // https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/JS-SDK.html#44
          wx.hideMenuItems({
            menuList: hideMenuItems
          })
        } else {
          // 默认情况展示所有按钮
          wx.showOptionMenu()
          wx.showAllNonBaseMenuItem()
        }

        console.log('开始分享')

        if (wx.updateAppMessageShareData) {
          wx.updateAppMessageShareData({
            title: _data.title, // 分享标题
            desc: _data.remark, // 分享描述
            link: _data.jumpUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: process.env.VUE_APP_STATIC_URL + _data.imageUrl, // 分享图标
            success: function () {
              console.log('分享给朋友配置成功: updateAppMessageShareData')
              // 设置成功
            }
          })
        } else {
          wx.onMenuShareAppMessage({
            title: _data.title, // 分享标题
            desc: _data.remark, // 分享描述
            link: _data.jumpUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: process.env.VUE_APP_STATIC_URL + _data.imageUrl, // 分享图标
            type: '', // 分享类型,music、video或link，不填默认为link
            dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
            success: function () {
              console.log('分享给朋友配置成功: onMenuShareAppMessage')
            }
          })
        }

        if (wx.updateTimelineShareData) {
          wx.updateTimelineShareData({
            title: _data.title, // 分享标题
            link: _data.jumpUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: process.env.VUE_APP_STATIC_URL + _data.imageUrl, // 分享图标
            success: function () {
              console.log('分享朋友圈配置成功: updateTimelineShareData')
              // 设置成功
            }
          })
        } else {
          wx.onMenuShareTimeline({
            title: _data.title, // 分享标题
            link: _data.jumpUrl, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: process.env.VUE_APP_STATIC_URL + _data.imageUrl, // 分享图标
            success: function () {
              console.log('分享朋友圈配置成功: onMenuShareTimeline')
            }
          })
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
}

export default initWechatShare
