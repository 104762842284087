/*
 * @Author: 王浩
 * @Date: 2021-08-05 10:44:28
 * @Last Modified by: 王浩
 * @Last Modified time: 2021-12-06 22:16:31
 * 结果页相关
 */
import { RouteConfig } from 'vue-router'
const basePath = '/result'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/result',
    name: 'result',
    component: () => import('@/views/result/result.vue'),
    meta: {
      title: '结果'
    }
  },
  {
    path: basePath + '/interpret_result',
    name: 'interpret_result',
    component: () => import('@/views/result/buyInterpretResult.vue'),
    meta: {
      title: '结果'
    }
  },
  {
    path: basePath + '/health-result',
    name: 'healthBuyResult',
    component: () => import('@/views/result/healthBuyResult.vue'),
    meta: {
      title: '购买成功'
    }
  }
]

export default Routes
