const reportV3Index = () => import('@/views/report_v3/index.vue')

import { RouteConfig } from 'vue-router'

const basePath = '/report_v3'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/index',
    name: 'reportV3Index',
    component: reportV3Index,
    meta: {
      title: '体检报告'
    }
  }
]

export default Routes
