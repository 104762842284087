/* * @Author: 王浩 * @Date: 2021-07-29 09:23:28 * @Last Modified by: 王浩 * @Last Modified time: 2021-07-29 09:23:28 */

<template>
  <div class="sz-fixed-footer-warpper">
    <div class="sz-fixed-footer">
      <div class="sz-fixed-footer-item" :class="{ active: name === 'home' }" @click.stop="goUrl('home')">
        <div class="sz-fixed-footer-item__icon">
          <sz-icon class="ft22" name="shouye-yixuan" v-if="name === 'home'"></sz-icon>
          <sz-icon class="ft22" name="shouye-weixuan" v-else></sz-icon>
        </div>
        <div class="sz-fixed-footer-item__name">首页</div>
      </div>

      <div class="sz-fixed-footer-item" :class="{ active: ['goodsIndex', 'goodsList'].includes(name) }" @click.stop="goUrl('goodsIndex')">
        <div class="sz-fixed-footer-item__icon">
          <sz-icon class="ft22" name="tijian-yixuan" v-if="['goodsIndex', 'goodsList'].includes(name)"></sz-icon>
          <sz-icon class="ft22" name="tijian-weixuan" v-else></sz-icon>
        </div>
        <div class="sz-fixed-footer-item__name">体检</div>
      </div>

      <div class="sz-fixed-footer-item" :class="{ active: name === 'user' }" @click.stop="goUrl('user')">
        <div class="sz-fixed-footer-item__icon">
          <sz-icon class="ft22" name="wode-yixuan" v-if="name === 'user'"></sz-icon>
          <sz-icon class="ft22" name="wode-weixuan" v-else></sz-icon>
        </div>
        <div class="sz-fixed-footer-item__name">我的</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCookie } from '@/module/tools' // 导入工具包
export default {
  name: '',
  data() {
    return {
      tabName: getCookie('tabName'), // 从 cookie 中获取tabName，可以参看view/insur/index.vue中的setTabName方法
      isSz: process.env.VUE_APP_BASE_PATH === '/wap' || sessionStorage.getItem('/wap'),
      examTabUrl: ''
    }
  },
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  created() {
    this.ajaxGetExamTabUrl()
  },
  methods: {
    goUrl(type) {
      switch (type) {
        case 'home':
          this.$router.push({ name: 'home' })
          break
        case 'goodsIndex':
          sessionStorage.setItem('currentTab', 0)
          location.href = this.examTabUrl
          break
        case 'consult':
          this.$router.push({
            name: 'courseList',
            query: { pageSource: 'tab' }
          })
          break
        case 'report':
          this.checkLogin(() => {
            this.$router.push({
              name: 'userReportList',
              query: { pageSource: 'tab' }
            })
          }, '/wap2/report_v2/compare')
          break
        case 'user':
          this.$router.push({
            name: 'userIndex',
            query: { pageSource: 'tab' }
          })
          break
        case 'insur':
          this.$router.push({
            name: 'insurIndex',
            query: { pageSource: 'tab' }
          })
          break
      }
    },
    checkLogin(cb, backUrl) {
      // 如果用户登录了则继续，否则去登录
      if (getCookie('islogin') === '1') {
        cb()
      } else {
        this.$router.push({ name: 'login', query: { backUrl: backUrl } })
      }
    },
    // 获取体检tab的跳转url
    ajaxGetExamTabUrl() {
      this.$api
        .slget(
          'cgi_commonDictQueryByKeyAndName',
          {
            key: 'EXAM_TAB_URL',
            name: 'EXAM_TAB_URL'
          },
          { cache: true }
        )
        .then(res => {
          if (res.SZ_HEAD.RESP_CODE === 'S0000') {
            const tabUrl = res.SZ_BODY?.cacheDto?.content
            if (tabUrl) {
              const tabUrlArr = tabUrl.split(',')
              const len = tabUrlArr.length
              if (len >= 2) {
                this.examTabUrl = this.$szGrayTest.examTabUrl ? tabUrlArr[0] : tabUrlArr[1]
              } else {
                this.examTabUrl = tabUrlArr[0]
              }
            } else {
              this.examTabUrl = 'https://m.shanzhen.com/wap2/goods/goods_index'
            }
          } else {
            this.$toast(res.SZ_HEAD.RESP_MSG)
          }
        })
    }
  },
  computed: {
    isShow() {
      return document.title !== ''
    },
    title() {
      console.log(document.title)
      return document.title
    }
  },
  components: {}
}
</script>

<style scoped lang="scss">
.sz-fixed-footer-warpper {
  height: 120px;
}
.sz-fixed-footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: flex;
  height: 120px;
  background: #fff;
  box-shadow: 0px -1px 6px 0px rgba(0, 0, 0, 0.08);
  &-item {
    flex: 1;
    box-sizing: border-box;
    height: 120px;
    padding-top: 18px;
    text-align: center;
    color: $sz-color-gray;
    &.active {
      color: #08c393;
    }
    &__icon {
      display: inline-block;
      width: pxToRem(22);
      height: pxToRem(22);
    }
    &__name {
      font-size: pxToRem(10);
    }
  }
}
.dotted {
  position: relative;
  &::after {
    position: absolute;
    top: 0rem;
    right: 0rem;
    width: 1rem;
    height: 1rem;
    background: #fe5c5c;
    border-radius: 50%;
    transform: scale(0.1);
    transform-origin: 100% 0;
    content: '';
  }
}
</style>
