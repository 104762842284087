/**
 * 美年大健康授权电子报告
 */

const authorise = () => import(/* webpackChunkName: "authorizationAuthorise" */ '@/views/authorization/authorise.vue')
const result = () => import(/* webpackChunkName: "authorizationResult" */ '@/views/authorization/result.vue')
import { RouteConfig } from 'vue-router'
const basePath = '/authorization'

const Routes: Array<RouteConfig> = [
  {
    path: basePath + '/authorise',
    name: 'authorizationAuthorise',
    component: authorise,
    meta: {
      title: '授权获取报告'
    }
  },
  {
    path: basePath + '/result',
    name: 'authorizationResult',
    component: result,
    meta: {
      title: '授权获取报告'
    }
  }
]
export default Routes
